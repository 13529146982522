import { InstructionState, ActionType } from './types';
import { instructionConstants } from './constants';

// INITIAL STATE
const initialState: InstructionState = {
  instruction: null,
  instructionList: [],
  totalInstructions: 0,
  instructionStats: [],
  employeeStats: [],
  locationStats: [],
  instructionAssignment: null,
  problem: null,
  loading: '',
  success: '',
};

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const instructionReducer = (state = initialState, action: ActionType): InstructionState => {
  switch (action.type) {
    case instructionConstants.INSTRUCTIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
        problem: action.payload ? null : state.problem,
        success: action.payload ? '' : state.success,
      };

    case instructionConstants.INSTRUCTIONS_ERRORED:
      return {
        ...state,
        problem: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SET: {
      return {
        ...state,
        instruction: action.payload,
      };
    }

    case instructionConstants.INSTRUCTIONS_SET_ASSIGNMENT:
      return {
        ...state,
        instructionAssignment: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SET_ALL:
      return {
        ...state,
        instructionList: action.payload.instructions || [],
        totalInstructions: action.payload.totalInstructions || 0,
      };

    case instructionConstants.INSTRUCTIONS_SET_ALL_STATS:
      return {
        ...state,
        instructionStats: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SET_ALL_EMPLOYEE_STATS:
      return {
        ...state,
        employeeStats: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_SET_ALL_LOCATION_STATS:
      return {
        ...state,
        locationStats: action.payload,
      };

    case instructionConstants.INSTRUCTIONS_INIT:
      return {
        ...initialState,
      };

    case 'dSimple/RESET_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
