import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AuthRoutes, NonAuthRoutes } from '../../lib/constants/pagePaths';
import { UmoAuthRoutes, UmoNoneAuthRoutes } from '../lib/constants/pagePaths';
import { AuthRoute } from '../../routes/AuthRoute';
import { NonAuthRoute } from '../../routes/NonAuthRoute';
import { ReAuthenticateDialog } from '../../components/organisms/window/ReAuthenticateDialog';
import { ChangeStartPasswordDialog } from '../../components/organisms/window/ChangeStartPasswordDialog';
import { useRoutes } from '../../routes/hooks/useRoutes';
import * as CorePermissionChecker from '../../lib/utils/permissionChecker';
import * as UmoPermissionChecker from '../lib/utils/permissionChecker';

// componentes + thunks
import Organisation from '../../pages/Organisation';
import Login from '../../pages/Login';
import Register from '../../pages/Register';
import Forgot from '../../pages/Forgot';
import Reset from '../../pages/Reset';
import NotFound from '../../pages/NotFound';
import Unauthorized from '../../pages/Unauthorized';
import Splash from '../../pages/Splash';
import { setHomeUrl } from '../../lib/store/account/actions';
import { Tutorial } from '../../components/organisms/window/Tutorial';
import { coreBasicTutorialSteps, getUmoBasicNextSteps } from './tutorial.steps';
import { RouteTracker } from '../../features/RouteTracker';

import { useCheckAccountToken } from '../../lib/hooks/useCheckAccountToken';
import InstructionsWizard from '../features/InstructionsWizard';
import { queryParams } from '../../lib/utils/queryParams';
import { getSideBarItems } from './getSideBarItems';

// const Dashboard = React.lazy(() => import('../../pages/Dashboard'));
const Billing = React.lazy(() => import('../../pages/Billing'));

const CompanyStructure = React.lazy(() => import('../../pages/CompanyStructure'));
const UserManagement = React.lazy(() => import('../../pages/UserManagement'));
const RoleManagement = React.lazy(() => import('../../pages/RoleManagement'));
const SystemSettings = React.lazy(() => import('../../pages/SystemSettings'));
const MyInstructions = React.lazy(() => import('../pages/MyInstructions'));
const MyInstructionsView = React.lazy(() => import('../pages/MyInstructionsView'));
const Archive = React.lazy(() => import('../pages/Archive'));
const Settings = React.lazy(() => import('../pages/Settings'));
const InstructionsOverview = React.lazy(() => import('../pages/instructions/InstructionsOverview'));
const InstructionsPreview = React.lazy(() => import('../pages/instructions/InstructionsPreview'));
const InstructionsTemplates = React.lazy(() => import('../pages/instructions/InstructionsTemplates'));

const InstructionsCreate = React.lazy(() => import('../pages/instructions/InstructionsCreate'));
const InstructionsEdit = React.lazy(() => import('../pages/instructions/InstructionsEdit'));
const InstructionsTranslate = React.lazy(() => import('../pages/instructions/InstructionsTranslate'));
const InstructionsAssign = React.lazy(() => import('../pages/instructions/InstructionsAssign'));

const TermControl = React.lazy(() => import('../pages/TermControl'));
const OpenInstruction = React.lazy(() => import('../pages/OpenInstruction'));

export const Routes: React.FC = () => {
  dayjs.extend(utc);
  const { t } = useTranslation(['menu']);
  const { profileChecked, workspaceChecked, account, isAuthenticated, reauthenticate } = useRoutes();
  const reduxDispatch = useDispatch();
  const permissions = (account && account.role && account.role.permissions) || [];

  useCheckAccountToken(account, reauthenticate);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs')); // Detects mobile screens

  const hasTermControlPermissions = UmoPermissionChecker.hasTermControlPermissions(permissions);

  React.useEffect(() => {
    reduxDispatch(
      setHomeUrl(
        isMobile
          ? UmoAuthRoutes.myInstructions
          : hasTermControlPermissions
          ? UmoAuthRoutes.termControl
          : UmoAuthRoutes.myInstructions,
      ),
    );
  }, [isAuthenticated, hasTermControlPermissions, reduxDispatch, isMobile]);

  // Show Splash screen until org + account checked
  if (!profileChecked || !workspaceChecked) {
    return <Splash />;
  }

  const sideBarItems = isMobile ? [] : getSideBarItems(permissions, t);

  const moduleTitle = 'umo';

  return (
    <Router>
      <RouteTracker />
      <Suspense fallback={<Splash />}>
        <Switch>
          <NonAuthRoute exact path="/" Component={Login} />
          <NonAuthRoute path={NonAuthRoutes.forgot} Component={Forgot} />
          <NonAuthRoute path={NonAuthRoutes.reset} Component={Reset} />
          <NonAuthRoute path={NonAuthRoutes.login} Component={Login} />
          <NonAuthRoute path={NonAuthRoutes.register} Component={Register} />
          <NonAuthRoute path={NonAuthRoutes.workspace} Component={Organisation} />

          <NonAuthRoute
            shouldRedirect={false}
            path={`${UmoNoneAuthRoutes.viewOpenInstruction}/:instructionId`}
            Component={OpenInstruction}
          />

          <NonAuthRoute
            shouldRedirect={false}
            path={UmoNoneAuthRoutes.viewInstructionTemplates}
            Component={InstructionsTemplates}
          />

          {/* <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={AuthRoutes.dashboard}
            Component={hasDashboardPermissions ? Dashboard : null}
          /> */}

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={[`${AuthRoutes.companyStructure}/:tab`, AuthRoutes.companyStructure]}
            Component={CorePermissionChecker.hasCompanyStructurePermissions(permissions) ? CompanyStructure : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={AuthRoutes.billing}
            Component={CorePermissionChecker.hasBillingPermissions(permissions) ? Billing : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={AuthRoutes.userManagement}
            stretch
            Component={CorePermissionChecker.hasUserManagementPermissions(permissions) ? UserManagement : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={AuthRoutes.roleManagement}
            Component={CorePermissionChecker.hasRoleManagementPermissions(permissions) ? RoleManagement : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={AuthRoutes.systemSettings}
            Component={CorePermissionChecker.hasSystemSettingsPermissions(permissions) ? SystemSettings : null}
          />
          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            exact
            path={[UmoAuthRoutes.myInstructions, `${UmoAuthRoutes.myInstructions}/:tab`]}
            Component={UmoPermissionChecker.hasMyInstructionsPermissions(permissions) ? MyInstructions : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={UmoAuthRoutes.archive}
            Component={UmoPermissionChecker.hasArchivePermissions(permissions) ? Archive : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={UmoAuthRoutes.settings}
            Component={UmoPermissionChecker.hasSettingsPermissions(permissions) ? Settings : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={UmoAuthRoutes.instructionsOverview}
            exact
            Component={
              UmoPermissionChecker.hasInstructionsOverviewPermissions(permissions) ? InstructionsOverview : null
            }
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={`${UmoAuthRoutes.instructionsPreview}:instructionId`}
            Component={UmoPermissionChecker.hasInstructionsPreviewPermissions(permissions) ? InstructionsPreview : null}
            stretch
            sliderMode
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={`${UmoAuthRoutes.myInstructionsView}:instructionId`}
            Component={UmoPermissionChecker.hasMyInstructionsViewPermissions(permissions) ? MyInstructionsView : null}
            stretch
            sliderMode
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={`${UmoAuthRoutes.instructionsEdit}:instructionId/translation/:lng`}
            Component={
              UmoPermissionChecker.hasInstructionsTranslatePermissions(permissions) ? InstructionsTranslate : null
            }
          />
          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={`${UmoAuthRoutes.instructionsEdit}:instructionId`}
            Component={UmoPermissionChecker.hasInstructionsEditPermissions(permissions) ? InstructionsEdit : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={`${UmoAuthRoutes.instructionsAssign}:instructionId`}
            Component={UmoPermissionChecker.hasInstructionsAssignPermissions(permissions) ? InstructionsAssign : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={UmoAuthRoutes.instructionsCreate}
            Component={UmoPermissionChecker.hasInstructionsCreatePermissions(permissions) ? InstructionsCreate : null}
          />

          <AuthRoute
            isAuthenticated={isAuthenticated}
            moduleTitle={moduleTitle}
            sideBarItems={sideBarItems}
            path={[`${UmoAuthRoutes.termControl}/:tab`, UmoAuthRoutes.termControl]}
            Component={UmoPermissionChecker.hasTermControlPermissions(permissions) ? TermControl : null}
          />

          <Route path={NonAuthRoutes.unauthorized} component={Unauthorized} />

          <Route component={NotFound} />
        </Switch>

        <Tutorial
          steps={coreBasicTutorialSteps}
          getNextSteps={getUmoBasicNextSteps}
          tutorialName="core:basic"
          onCompleteTutorial={(history) => {
            queryParams.add(history, 'drawer', 'wizard');
          }}
        />

        <ReAuthenticateDialog />
        <ChangeStartPasswordDialog />
        {isAuthenticated && UmoPermissionChecker.hasInstructionsEditPermissions(permissions) && <InstructionsWizard />}
      </Suspense>
    </Router>
  );
};
