import React from 'react';
import { AssignmentRounded, PollRounded, ArchiveRounded, SettingsRounded, SchoolRounded } from '@material-ui/icons';
import { TFunction } from 'i18next';
import { PermissionType } from '../../lib/store/role/types';
import { UmoAuthRoutes } from '../lib/constants/pagePaths';
import * as UmoPermissionChecker from '../lib/utils/permissionChecker';
import { SideBarItem } from '../../components/organisms/drawer/BaseSideBar/types';

export const getSideBarItems = (permissions: PermissionType[], t: TFunction, isSecondary?: boolean): SideBarItem[] => {
  const sideBarItems = [
    ...(UmoPermissionChecker.hasInstructionsOverviewPermissions(permissions)
      ? [
          {
            text: t('menu:sidebar.overviewInstructions'),
            to: UmoAuthRoutes.instructionsOverview,
            icon: (
              <AssignmentRounded
                color={isSecondary ? 'secondary' : 'primary'}
                fontSize={isSecondary ? undefined : 'large'}
              />
            ),
          },
        ]
      : []),
    ...(UmoPermissionChecker.hasTermControlPermissions(permissions)
      ? [
          {
            text: t('menu:sidebar.termControl'),
            to: UmoAuthRoutes.termControl,
            icon: (
              <PollRounded color={isSecondary ? 'secondary' : 'primary'} fontSize={isSecondary ? undefined : 'large'} />
            ),
          },
        ]
      : []),
    ...(UmoPermissionChecker.hasArchivePermissions(permissions)
      ? [
          {
            text: t('menu:sidebar.archive'),
            to: UmoAuthRoutes.archive,
            icon: (
              <ArchiveRounded
                color={isSecondary ? 'secondary' : 'primary'}
                fontSize={isSecondary ? undefined : 'large'}
              />
            ),
          },
        ]
      : []),

    ...(UmoPermissionChecker.hasSettingsPermissions(permissions)
      ? [
          {
            text: t('menu:sidebar.settings'),
            to: UmoAuthRoutes.settings,
            icon: (
              <SettingsRounded
                color={isSecondary ? 'secondary' : 'primary'}
                fontSize={isSecondary ? undefined : 'large'}
              />
            ),
          },
        ]
      : []),
  ];

  if (sideBarItems.length > 0) {
    sideBarItems.splice(0, 0, {
      text: t('menu:sidebar.myInstructions'),
      to: UmoAuthRoutes.myInstructions,
      icon: (
        <SchoolRounded color={isSecondary ? 'secondary' : 'primary'} fontSize={isSecondary ? undefined : 'large'} />
      ),
    });
  }

  return sideBarItems;
};
