import { TranslationsAvailable } from './types';

export const TRANSLATIONS_AVAILABLE: TranslationsAvailable[] = [
  'de-DE' as const,
  'en-GB' as const,
  'pl-PL' as const,
  'es-ES' as const,
  'fr-FR' as const,
  'ru-RU' as const,
  'cz-CZ' as const,
];

export enum instructionConstants {
  INSTRUCTIONS_LOADING = 'dSimple/instruction/LOADING',
  INSTRUCTIONS_ERRORED = 'dSimple/instruction/ERRORED',
  INSTRUCTIONS_SUCCESS = 'dSimple/instruction/SUCCESS',
  INSTRUCTIONS_SET = 'dSimple/instruction/SET',
  INSTRUCTIONS_SET_ASSIGNMENT = 'dSimple/instruction/SET_ASSIGNMENT',
  INSTRUCTIONS_SET_ALL = 'dSimple/instruction/SET_ALL',
  INSTRUCTIONS_SET_ALL_STATS = 'dSimple/instruction/SET_ALL_STATS',
  INSTRUCTIONS_SET_ALL_EMPLOYEE_STATS = 'dSimple/instruction/SET_ALL_EMPLOYEE_STATS',
  INSTRUCTIONS_SET_ALL_LOCATION_STATS = 'dSimple/instruction/INSTRUCTIONS_SET_ALL_LOCATION_STATS',
  INSTRUCTIONS_INIT = 'dSimple/instruction/INIT',
}
